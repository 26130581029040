import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import { Redirect } from 'react-router';
import Home from './Components/Home';
import TrainingMaterials from './Containers/TrainingMaterials';
import OfficialCrmIcons from './Containers/OfficialCrmIcons';
import Presentations from './Containers/Presentations';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
    <Redirect from="/" to="/training-materials" />
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Home
    },
    {
      path: '/training-materials',
      exact: true,
      component: TrainingMaterials,
    },
    {
      path: '/official-crm-icons',
      exact: true,
      component: OfficialCrmIcons,
    },
    {
      path: '/presentations',
      exact: true,
      component: Presentations,
    },
    {
      path: '/id-homepage',
      exact: true,
      component: IDHomepage,
    },
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};
