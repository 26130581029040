import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Home extends React.PureComponent {
  render() {
    const { t } = this.props;
    return <div style={{ margin: '24px', height: '80vh' }}>{t('welcome')}</div>;
  }
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['home'])(Home);
